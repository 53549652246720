import { Input } from "reactstrap";

export const hasValue = (val) => {
  return val !== null && val !== undefined && val.toString().trim().length > 0;
};

export const RequestType = {
  Questionnaire: "Questionnaire",
  DocumentRequest: "Document request",
};

export const ResponseType = {
  Yes: "Y",
  No: "N",
  NA: "NA",
};

export const SubmissionStatus = {
  SubmittedToVendor: "Submitted To Vendor",
  ResponseReceived: "Response Received",
  Accepted: "Accepted",
  ReturnToVendor: "Return To Vendor",
};

export const MUIDataGridTheme = {
  "& .disabled-row": {
    backgroundColor: "#eff7f1",
  },
};

export const CustomCheckBox = (props) => (
  <Input className="custom-check-box" type="checkbox" {...props} />
);

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

export const checkPasswordValidations = (
  newPassword,
  confirmPassword,
  enqueueSnackbar
) => {
  if (!hasValue(newPassword)) {
    enqueueSnackbar("Please Enter New Password", {
      variant: "error",
    });
    return false;
  }

  if (!hasValue(confirmPassword)) {
    enqueueSnackbar("Please Enter Confirm Password", {
      variant: "error",
    });
    return false;
  }

  if (newPassword !== confirmPassword) {
    enqueueSnackbar("New Password and Confirm Password does not match", {
      variant: "error",
    });
    return false;
  }

  if (!passwordRegex.test(newPassword)) {
    let ToastContent = () => (
      <>
        <b>New Password should meet following criteria.</b>
        <p>1. Should contain Min. 6 - Max. 16 characters</p>
        <p>2. Should contain atleast 1 number</p>
        <p>
          3. Should contain atleast 1 special character {" ! @ # $ % ^ & *"}
        </p>
      </>
    );
    enqueueSnackbar(<ToastContent />, {
      variant: "error",
    });
    return false;
  }

  return true;
};

export const ZammadTicketStates = {
  New: "New",
  InProgress: "In progress",
  PendingWithBusiness: "Pending with Business",
  Completed: "Completed",
  Reopened: "Reopened",
  Approved: "Approved",
};

export const ZammadTicketStatesMap = {
  New: { label: "New", color: "success" },
  "In progress": { label: "In progress", color: "warning" },
  "Pending with Business": { label: "In progress", color: "warning" },
  Completed: { label: "Completed", color: "success" },
  Reopened: { label: "In progress", color: "warning" },
  Approved: { label: "In progress", color: "warning" },
};

export const capitalizeFirstLetter = (string) => {
  if (string?.length > 0)
    return string.charAt(0).toUpperCase() + string.slice(1);
  else return "";
};

export const getColorCodeForRisks = (riskLevel) => {
  const red = ["high", "not effective"];
  const amber = ["medium", "meidum", "partially effective"];
  const green = ["low", "fully effective"];
  const yellow = ["mostly effective"];

  if (red.includes(riskLevel?.toLowerCase())) {
    return "red";
  } else if (amber.includes(riskLevel?.toLowerCase())) {
    return "amber";
  } else if (green.includes(riskLevel?.toLowerCase())) {
    return "green";
  } else if (yellow.includes(riskLevel?.toLowerCase())) {
    return "yellow";
  } else {
    return "";
  }
};

export const getDataTypesInvlovedInReport = (value) => {
  var stringType = "Public";
  switch (value) {
    case "2":
      stringType = "Internal";
      break;
    case "3":
      stringType = "Confidential";
      break;
    case "4":
      stringType = "Secret";
      break;
    default:
      stringType = "Public";
      break;
  }
  return stringType;
};

export const ascSortFn = (a, b) => {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

export const BusinessDomainTypes = {
  Sections: "1",
  Table: "2",
  List: "3",
};
