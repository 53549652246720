import React from "react";
import ReactDOM from "react-dom/client";
import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";
import "./assets/base.css";
import "./assets/template-override.css";
import Main from "./pages/Main";
import App from "./v2/App";

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);

const renderApp = (Component) => {
  root.render(<App />);
};

renderApp(Main);

unregister();

registerServiceWorker();
