import React, { useState } from 'react'

import styles from './style.module.css'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { InfoIcon } from 'src/v2/icons/info'
import { hasValue } from 'src/utils/commonFns'
import clsx from 'clsx'

export const TitleCaption = ({ title, caption, titleClassName }) => {
  const [showCaption, setShowCaption] = useState(true)
  return (
    <div className={styles['title-caption']}>
      <div className={clsx(styles.level, titleClassName)}>
        <div className={styles['text-wrapper']}>{title}</div>
        {hasValue(caption) && (
          <IconPlaceholder
            icon={<InfoIcon className={styles['icons-1']} color={'var(--info-color-IC-l-300)'} />}
            size="twenty-x-20"
            onClick={() => {
              setShowCaption(!showCaption)
            }}
          />
        )}
      </div>
      {showCaption && <p className={styles.div}>{caption}</p>}
      {!showCaption && <p className={styles.div}></p>}
    </div>
  )
}
