import React from "react";
import { Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import clsx from "clsx";

const ChooseFileDragnDrop = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { files, setFiles, isReadOnlyMode, isSingle } = props;

  const onDropHandler = (ev) => {
    ev.preventDefault();
    if (!isReadOnlyMode) return;
    let selectedFiles = [];
    if (ev.dataTransfer?.items) {
      selectedFiles = [...ev.dataTransfer?.items]
        .filter((item) => item.kind === "file")
        .map((x) => x.getAsFile());
    } else {
      selectedFiles = [...ev.dataTransfer.files];
    }
    if (selectedFiles.some((x) => files.some((y) => y.name === x.name))) {
      enqueueSnackbar("Please remove duplicate file(s)", {
        variant: "warning",
      });
      return;
    }
    if (selectedFiles.some((x) => !!x.size && x.size >= 21000000)) {
      enqueueSnackbar("Please select file size less than 20 MB", {
        variant: "warning",
      });
      return;
    }
    setFiles([...files, ...selectedFiles]);
  };

  const onFileSelect = (event) => {
    if (!isReadOnlyMode) return;
    let selectedFiles = [...event.target.files];
    if (selectedFiles.some((x) => files.some((y) => y.name === x.name))) {
      enqueueSnackbar("Please remove duplicate file(s)", {
        variant: "warning",
      });
      return;
    }
    if (selectedFiles.some((x) => !!x.size && x.size >= 21000000)) {
      enqueueSnackbar("Please select file size less than 20 MB", {
        variant: "warning",
      });
      return;
    }
    setFiles([...files, ...event.target.files]);
  };

  const onDragOver = (ev) => ev.preventDefault();

  return (
    <>
      <div
        id="drop_zone"
        className={clsx("file-drop-zone", !isSingle && "mt-3")}
        onDrop={onDropHandler}
        onDragOver={onDragOver}
        style={isSingle ? { width: "100%" } : {}}
      >
        <div>
          <FontAwesomeIcon
            icon={faCloudArrowUp}
            style={{ fontSize: "26px", color: "#628902" }}
          />
        </div>
        <div style={{ fontSize: "16px" }}>Drag files to upload</div>
        <div> or </div>
        <Label
          for="file_picker"
          className="file-label cursor-pointer"
          style={{ padding: "4px", margin: "0px 40px 8px 40px" }}
        >
          Browse Files
        </Label>
        <Input
          id="file_picker"
          disabled={!isReadOnlyMode}
          multiple={isSingle ? false : true}
          type="file"
          onChange={(event) => onFileSelect(event)}
          value=""
          style={{ display: "none" }}
        ></Input>
        <div className="inline-text">
          <p className="inline-p">Max file size:</p> 20MB
        </div>
        <div className="inline-text">
          <p className="inline-p">Supported file types:</p> JPG, PNG, GIF, PDF,
          CSV, DOC
        </div>
      </div>
    </>
  );
};

export default ChooseFileDragnDrop;
