import React, { useEffect, useMemo, useState } from "react";
import styles from "./style.module.css";
import { RatingSummary } from "./RatingSummary";
import { TitleCaption } from "./TitleCaption";
import { ControlTestingMetrics } from "./ControlTestingMetrics";
import { DomainSummary } from "./DomainSummary";
import DomainSummaryDetails from "./DomainSummary/Details";
import { MitreCoverage } from "./MitreCoverage";
import { PrivacyImpact } from "./PrivacyImpact";
import { NistFramework } from "./NistFramework";
import { capitalizeFirstLetter } from "src/utils/commonFns";
import { useAuthState } from "src/context/login";
import { getClientSubmissionDetails } from "src/services/vendorService";
import { Remediations } from "./Remediations";

export const AssessmentReport = () => {
  const [openDetails, setOpenDetails] = useState(false);
  const [domainInfo, setDomainInfo] = useState(null);

  const { user } = useAuthState();

  const handleDomainSummaryDetails = (open, domainInfo) => {
    setOpenDetails(open);
    if (open) setDomainInfo(domainInfo);
    else setDomainInfo(null);
  };

  const [assessmentDetails, setAssessmentDetails] = useState(null);

  const fetchAssessmentDetails = async () => {
    const response = await getClientSubmissionDetails(user.vendorId);
    setAssessmentDetails(response);
  };

  const questionaireData = useMemo(
    () =>
      assessmentDetails != null
        ? JSON.parse(assessmentDetails?.preAssessmentQuestionnaire)
        : undefined,
    [assessmentDetails]
  );

  const reportData = useMemo(
    () =>
      assessmentDetails != null
        ? JSON.parse(assessmentDetails?.reportData)
        : undefined,
    [assessmentDetails]
  );

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  return (
    <>
      <div className={styles["report-wrapper"]}>
        {!!reportData?.assessmentRisk ? (
          <RatingSummary
            reportData={reportData}
            thirdPartyName={capitalizeFirstLetter(
              questionaireData?.thirdPartyName?.trim() ?? ""
            )}
            businessOrgName={questionaireData?.businessOrgName}
            questionaireData={questionaireData}
          />
        ) : null}

        {!!reportData?.controlTestingMetrics ? (
          <>
            <TitleCaption
              title={"Control Testing Metrics"}
              caption={
                <>
                  This Section provides the High level results of the assessment
                  for
                  <b>
                    {" "}
                    {capitalizeFirstLetter(
                      questionaireData?.thirdPartyName?.trim() ?? ""
                    )}{" "}
                  </b>
                  based on a maturity model and completeness of the control
                  implementation.
                </>
              }
            />
            <ControlTestingMetrics reportData={reportData} />
          </>
        ) : null}
        {!!reportData?.mitreFrameworkCoverage ? (
          <>
            <TitleCaption
              title={"MITRE ATT&CK coverage"}
              caption={
                <>
                  This section highlights the high-level results of our MITRE
                  ATT&CK coverage for
                  <b>
                    {" "}
                    {capitalizeFirstLetter(
                      questionaireData?.thirdPartyName?.trim() ?? ""
                    )}{" "}
                  </b>
                  based on our control mapping and their responses.
                </>
              }
            />
            <MitreCoverage
              reportData={reportData}
              thirdPartyName={capitalizeFirstLetter(
                questionaireData?.thirdPartyName?.trim() ?? ""
              )}
            />{" "}
          </>
        ) : null}
        {!!reportData?.nistRating || !!reportData?.recentSecurityIncidents ? (
          <>
            <TitleCaption
              title={"NIST Cybersecurity Framework (CSF) Score"}
              caption={
                <>
                  This section presents an overview of our NIST mapping for
                  <b>
                    {" "}
                    {capitalizeFirstLetter(
                      questionaireData?.thirdPartyName?.trim() ?? ""
                    )}{" "}
                  </b>
                  reflecting the maturity of their control implementation.
                </>
              }
            />
            <NistFramework
              reportData={reportData}
              thirdPartyName={capitalizeFirstLetter(
                questionaireData?.thirdPartyName?.trim() ?? ""
              )}
            />{" "}
          </>
        ) : null}
        {!!reportData?.domains ? (
          <>
            <TitleCaption
              title={"Domain Summary"}
              caption={
                <>
                  This Section provides the High level results of the assessment
                  for
                  <b>
                    {" "}
                    {capitalizeFirstLetter(
                      questionaireData?.thirdPartyName?.trim() ?? ""
                    )}{" "}
                  </b>
                  based on a maturity model and completeness of the control
                  implementation.
                </>
              }
            />
            <DomainSummary
              reportData={reportData}
              handleDomainSummaryDetails={handleDomainSummaryDetails}
            />
          </>
        ) : null}
        {/* <>
          <TitleCaption
            title={"External attack surface"}
            caption={
              <>
                This section presents an overview of the External attack surface
                security posture of
                <b>
                  {" "}
                  {capitalizeFirstLetter(
                    questionaireData?.thirdPartyName?.trim() ?? ""
                  )}{" "}
                </b>
                through real-time vulnerability scanning.
              </>
            }
          />
          <AttackSurface
            insecureWebScanId={assessmentDetails?.insecureWebScanId}
            coalitionEntityId={assessmentDetails?.coalitionEntityId}
          />
        </>
        <>
          <TitleCaption
            title={"Darkweb Intelligence"}
            caption={
              <>
                This section presents an overview of the Darkweb intelligence
                gathered by real time scan of Dark/deep web for
                <b>
                  {" "}
                  {capitalizeFirstLetter(
                    questionaireData?.thirdPartyName?.trim() ?? ""
                  )}
                </b>
                .
              </>
            }
          />
          <DarkWebMonitoring
            insecureWebScanId={assessmentDetails?.insecureWebScanId}
          />
        </> */}
        {!!reportData?.privacyImpactAssessment &&
          !!reportData?.privacyImpactAssessment?.privacyScore &&
          !!reportData?.privacyImpactAssessment?.privacyScore
            ?.testedControls && (
            <>
              <TitleCaption
                title={"Privacy Impact Assessment"}
                caption={
                  <>
                    This section provides the High level results of the Privacy
                    Impact assessment for
                    <b>
                      {" "}
                      {capitalizeFirstLetter(
                        questionaireData?.thirdPartyName?.trim() ?? ""
                      )}{" "}
                    </b>
                    based on the Project scope and its privacy requirements.
                  </>
                }
              />
              <PrivacyImpact reportData={reportData} />
            </>
          )}
        {!!reportData?.gapsRecommendations ? (
          <>
            <TitleCaption
              title={"Remediation Gaps"}
              caption={
                <>
                  This Section provides the Remediation Gaps for
                  <b>
                    {" "}
                    {capitalizeFirstLetter(
                      questionaireData?.thirdPartyName?.trim() ?? ""
                    )}{" "}
                  </b>
                </>
              }
            />
            <Remediations assessmentDetails={assessmentDetails} />
          </>
        ) : null}
      </div>
      <DomainSummaryDetails
        open={openDetails}
        handleAction={setOpenDetails}
        domainInfo={domainInfo}
        reportData={reportData}
      />
    </>
  );
};
