import React from "react";
import styles from "./style.module.css";
import { RiskManagement } from "src/v2/icons/domainSummary/risk-management";
import { InfoIcon } from "src/v2/icons/info";
import { ThirdPartyRisk } from "src/v2/icons/domainSummary/third-party-risk";
import { VulnerabilityManagement } from "src/v2/icons/domainSummary/vulnerability-management";
import { BcpAndDr } from "src/v2/icons/domainSummary/bcp-dr";
import { NetworkSecurity } from "src/v2/icons/domainSummary/network-security";
import { ApplicatonSecurity } from "src/v2/icons/domainSummary/application-security";
import { IncidentManagement } from "src/v2/icons/domainSummary/incident-management";
import { AssestManagement } from "src/v2/icons/domainSummary/asset-management";
import { Card } from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/commonFns";
import { IconPlaceholder } from "src/v2/components/IconPlaceholder";

export const RemediationCard = ({ eachGap, setOpenDrawer }) => {
  const getDomainIcon = () => {
    var icon;
    var domainNameLowerCase = (
      eachGap.domainName ?? eachGap.domain
    ).toLowerCase();
    if (domainNameLowerCase.includes("third party")) {
      icon = <ThirdPartyRisk className={styles["icon-instance-node"]} />;
    } else if (domainNameLowerCase.includes("vulnerability"))
      icon = (
        <VulnerabilityManagement className={styles["icon-instance-node"]} />
      );
    else if (domainNameLowerCase.includes("asset"))
      icon = <AssestManagement className={styles["icon-instance-node"]} />;
    else if (domainNameLowerCase.includes("risk"))
      icon = <RiskManagement className={styles["icon-instance-node"]} />;
    else if (domainNameLowerCase.includes("bcp"))
      icon = <BcpAndDr className={styles["icon-instance-node"]} />;
    else if (domainNameLowerCase.includes("network"))
      icon = <NetworkSecurity className={styles["icon-instance-node"]} />;
    else if (domainNameLowerCase.includes("application"))
      icon = <ApplicatonSecurity className={styles["icon-instance-node"]} />;
    else if (domainNameLowerCase.includes("incident"))
      icon = <IncidentManagement className={styles["icon-instance-node"]} />;
    else if (
      domainNameLowerCase.includes("risk") ||
      domainNameLowerCase.includes("access")
    )
      icon = <RiskManagement className={styles["icon-instance-node"]} />;
    return icon;
  };

  // eslint-disable-next-line no-unused-vars
  const icon = getDomainIcon();

  return (
    <>
      <Card
        className={styles["remediation-cards"]}
        style={{ backgroundColor: "" }}
      >
        <div className={styles["header"]}>
          <div className={styles["level"]}>
            <div className={styles["content"]}>
              <div className={styles["title"]}>
                {/* {!!icon ? <IconPlaceholder icon={icon} size="thirty-two-x-32" /> : null} */}
                <div className={styles["text-wrapper"]}>
                  {eachGap.domainName ?? eachGap.domain}
                </div>
              </div>
              <div className={styles["section"]}>
                <div
                  className={`${styles.status} ${styles["status-instance"]} ${
                    styles[
                      (
                        eachGap.remediationGapStatus ?? "notinitiated"
                      ).toLowerCase()
                    ] || styles["other"]
                  } `}
                >
                  <div className={styles.statustext}>
                    {(eachGap.displayStatus ?? "NOT Initiated").toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["single-content-text"]}>
          <div className={styles["body-text"]}>ISSUE IDENTIFIED</div>
          <p className={styles["div"]}>{eachGap.issueIdentified}</p>
        </div>
        <div className={styles["footer"]}>
          <div className={styles["badge-status"]}>
            <IconPlaceholder
              icon={
                <InfoIcon
                  className={styles["icon-instance-node"]}
                  color={
                    eachGap.issueSeverity.toLowerCase() === "low"
                      ? "var(--confirmation-color-CC-l-150)"
                      : eachGap.issueSeverity.toLowerCase() === "medium"
                      ? "var(--warning-color-2-WC-l-150)"
                      : "var(--error-color-EC-l-150)"
                  }
                />
              }
              size="twenty-x-20"
            />
            <div
              className={`${styles["text-2"]} ${
                eachGap.issueSeverity.toLowerCase() === "low"
                  ? styles.lowrisk
                  : eachGap.issueSeverity.toLowerCase() === "medium"
                  ? styles.mediumrisk
                  : styles.highrisk
              }`}
            >
              {capitalizeFirstLetter(eachGap.issueSeverity)} Risk
            </div>
          </div>
          <div
            className={`${"menu-links"} ${styles["badge-statut-2"]} `}
            onClick={() => setOpenDrawer(eachGap)}
          >
            <div className={styles["text-3"]}>View Details</div>
          </div>
        </div>
      </Card>
    </>
  );
};
