import axios from "axios";
import AppConfig from "../../config";

const ROOT_URL = AppConfig.API_URL;

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let { data } = await axios.post(
      `${ROOT_URL}/users/authenticate`,
      loginPayload
    );

    if (!!data && !!data.id) {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      localStorage.setItem("currentUser", JSON.stringify(data));
      return data;
    }

    dispatch({ type: "LOGIN_ERROR", error: data.error });
    console.log(data.error);
    return;
  } catch (error) {
    dispatch({
      type: "LOGIN_ERROR",
      error: error?.response?.data?.error || error?.message,
    });
    console.log(error);
  }
}

export async function resetPassword(dispatch, resetPayload) {
  try {
    let { data } = await axios.post(
      `${ROOT_URL}/users/reset-password`,
      resetPayload
    );

    if (!!data.result && !!data.result.id) {
      dispatch({ type: "RESET_SUCCESS", payload: data.result });
      var currentUser = JSON.parse(localStorage.getItem("currentUser"));
      currentUser.isUserResetPassword = data.result.isUserResetPassword;
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      return data.result;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function verifyEmailCode(verifyPayload) {
  try {
    let { data } = await axios.post(
      `${ROOT_URL}/SendEmail/send-verification-code`,
      verifyPayload
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function resetForgotPassword(resetPayload) {
  try {
    let { data } = await axios.post(
      `${ROOT_URL}/users/reset-forgot-password`,
      resetPayload
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function logout(dispatch, payload = {}) {
  dispatch({ type: "LOGOUT", payload });
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
}

export async function validateForgotPasswordCode(validatePayload) {
  try {
    let { data } = await axios.post(
      `${ROOT_URL}/users/validate-forgot-password-code`,
      validatePayload
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}
