import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { SuccessTickIcon } from "src/v2/icons/success-tick";
import { FailedTickIcon } from "src/v2/icons/failed-tick";
import { DSDoughnutChart } from "./DSDoughnutChart";
import { NotApplicable } from "src/v2/icons/not-applicable";
import { IconPlaceholder } from "src/v2/components/IconPlaceholder";
import { ExpandIcon } from "src/v2/assets/icons/remediation/header/expand";
import { ExpandMore } from "src/v2/components/ExpandMore";
import { Collapse } from "@mui/material";
import { ascSortFn, BusinessDomainTypes } from "src/utils/commonFns";

export const DomainDetails = ({ key, eachDomain }) => {
  const [controlsTested, setControlsTested] = useState(0);
  const [passed, setPassed] = useState(0);
  const [failed, setFailed] = useState(0);
  const [notApplicable, setNotApplicable] = useState(0);
  const [isCollapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!isCollapse);
  };

  const getQuestionsCount = () => {
    var questionsList;
    if (eachDomain.questionsList) {
      questionsList = eachDomain.questionsList;
    } else {
      questionsList = eachDomain.sections.map((x) => x.questionsList).flat();
    }
    setPassed(questionsList.filter((x) => x.answer === "Y")?.length);
    setFailed(questionsList.filter((x) => x.answer === "N")?.length);
    setNotApplicable(
      questionsList.filter((x) => x.answer !== "N" && x.answer !== "Y")?.length
    );
    setControlsTested(questionsList.length);
  };

  useEffect(() => {
    if (eachDomain) getQuestionsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eachDomain]);

  return (
    <div className={styles["section-1"]}>
      <div className={styles["title-2"]}>
        <div className={styles["title-2-wrapper"]}>
          {eachDomain?.domainInfo?.domainName ?? eachDomain?.domainName}
        </div>
      </div>
      <div className={styles["chart-content"]}>
        <div className={styles.chart}>
          <div className={styles.graph}>
            <div className={styles["half-doughnut-chart"]}>
              <DSDoughnutChart
                percentage={parseInt(eachDomain?.percentage || 0)}
              ></DSDoughnutChart>
            </div>
          </div>
          <div className={styles["section-2"]}>
            <div className={styles.controlstested}>
              <div className={styles["icon-content"]}>
                <div className={styles["text-wrapper-control"]}>
                  Total Controls Tested
                </div>
              </div>
              <div className={styles.score}>
                <div className={styles["control-value"]}>
                  {eachDomain?.controlsTested || controlsTested}
                </div>
              </div>
            </div>
            <div className={styles["sub-section"]}>
              <div className={styles.controls}>
                <div className={styles["icon-content"]}>
                  <IconPlaceholder
                    icon={
                      <SuccessTickIcon
                        className={styles["icon-instance-node"]}
                      />
                    }
                    size="twenty-four-x-24"
                  />
                  <div className={styles["text-wrapper-control"]}>
                    Available
                  </div>
                </div>
                <div className={styles.controlvalues}>
                  <div className={styles["control-value"]}>
                    {eachDomain?.passed || passed}
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.controls}>
                <div className={styles["icon-content"]}>
                  <IconPlaceholder
                    icon={
                      <FailedTickIcon
                        className={styles["icon-instance-node"]}
                      />
                    }
                    size="twenty-four-x-24"
                  />
                  <div className={styles["text-wrapper-control"]}>Failed</div>
                </div>
                <div className={styles.controlvalues}>
                  <div className={styles["control-value"]}>
                    {eachDomain?.failed || failed}
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.controls}>
                <div className={styles["icon-content"]}>
                  <IconPlaceholder
                    icon={
                      <NotApplicable className={styles["icon-instance-node"]} />
                    }
                    size="twenty-four-x-24"
                  />
                  <div className={styles["text-wrapper-control"]}>NA</div>
                </div>
                <div className={styles.controlvalues}>
                  <div className={styles["control-value"]}>
                    {eachDomain?.notApplicable || notApplicable}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {eachDomain.domainType === BusinessDomainTypes.Sections && eachDomain.sections && ( */}
      <div className={styles.section}>
        <div className={styles["access-control"]}>
          <div className={styles.div}>
            {eachDomain.sections.map((eachSection, index) => (
              <>
                <div
                  className={styles["access-control-2"]}
                  onClick={() => {
                    eachSection.expanded = !eachSection.expanded;
                    toggle();
                  }}
                >
                  <div className={styles["div-2"]}>
                    <div className={styles["title-content"]}>
                      <div className={styles["text-wrapper"]}>
                        {" "}
                        {eachDomain.domainType === BusinessDomainTypes.Sections
                          ? eachSection.name
                          : eachDomain?.domainInfo?.domainName ??
                            eachDomain?.domainName}
                      </div>
                      <div className={styles["text-wrapper-2"]}>
                        {eachDomain.domainType === BusinessDomainTypes.Sections
                          ? eachSection.description
                          : "Controls related to " +
                            (eachDomain?.domainInfo?.domainName ||
                              eachDomain?.domainName)}
                      </div>
                    </div>
                    <ExpandMore
                      expand={eachSection.expanded}
                      onClick={() => {
                        eachSection.expanded = !eachSection.expanded;
                        toggle();
                      }}
                      aria-expanded={eachSection.expanded}
                      aria-label="show more"
                      id={eachSection.id}
                      rotate={"rotate(180deg)"}
                    >
                      <IconPlaceholder
                        icon={
                          <ExpandIcon
                            className={styles["icon-instance-node"]}
                          />
                        }
                        size="twenty-eight-x-28"
                        onClick={() => {
                          eachSection.expanded = !eachSection.expanded;
                          toggle();
                        }}
                      />
                    </ExpandMore>
                  </div>
                  <Collapse
                    in={eachSection.expanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <div className={styles["div-collpase"]}>
                      {eachSection.questionsList
                        .sort(ascSortFn)
                        .map((eachQuestion, index) => {
                          return (
                            <div key={index} className={styles["div-wrapper"]}>
                              <div className={styles["text-icon-4"]}>
                                <IconPlaceholder
                                  icon={
                                    eachQuestion.answer === "Y" ? (
                                      <SuccessTickIcon
                                        className={styles["icons-6"]}
                                      />
                                    ) : eachQuestion.answer === "N" ? (
                                      <FailedTickIcon
                                        className={styles["icons-6"]}
                                      />
                                    ) : (
                                      <NotApplicable
                                        className={styles["icons-6"]}
                                      />
                                    )
                                  }
                                  size="thirty-two-x-32"
                                />
                                <div className={styles["text-4"]}>
                                  <p className={styles["text-wrapper-4"]}>
                                    {eachQuestion.name}
                                  </p>
                                  {/* <div className={styles['text-wrapper-5']}>
                                  {eachQuestion.answer === 'Y'
                                    ? 'Yes'
                                    : eachQuestion.answer === 'N'
                                    ? 'No'
                                    : 'Not Applicable'}
                                </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Collapse>
                </div>
                {index < eachDomain.sections.length - 1 ? (
                  <div className={styles.border} />
                ) : null}
              </>
            ))}
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
