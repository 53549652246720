import React from 'react'
import styles from './style.module.css'
import { Modal } from '@mui/material'
import { IconPlaceholder } from 'src/v2/components/IconPlaceholder'
import { CrossMark } from 'src/v2/icons/cross-mark'
import { MetricCard } from 'src/v2/components/MetricCard'
import {
  capitalizeFirstLetter,
  getColorCodeForRisks,
  getDataTypesInvlovedInReport,
} from 'src/utils/commonFns'
import { Card } from 'reactstrap'

export const RatingSummaryDetails = ({ open, handleAction, reportData, questionaireData }) => {
  const privacyQuestionnaire = reportData.privacyImpactAssessment.privacyQuestionnaire

  const fourthPartyAccessAnswer =
    privacyQuestionnaire.find((x) => x.icon === 'FourthPartyAccess')?.answer?.toUpperCase() ?? 'N'

  const applyColorSchemeForRiskBoxes = (value) => {
    const result = getColorCodeForRisks(value)
    if (result === 'green') return 'success'
    else if (result === 'amber') return 'warning'
    else if (result === 'red') return 'error'
    else if (result === 'yellow') return 'mostlyeffective'
  }

  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.modal}>
        {' '}
        <div className={styles.title}>
          <div className={styles['title-icon']}>
            <div className={styles['text-wrapper-12']}>Detailed Summary</div>
          </div>
          <IconPlaceholder
            icon={<CrossMark className={styles['icons-6']} />}
            size="twenty-four-x-24"
            onClick={() => handleAction(false)}
          />
        </div>
        <div className={styles.section}>
          <Card className={styles['inherent-risk']}>
            <MetricCard
              color={`${applyColorSchemeForRiskBoxes(reportData?.assessmentRisk?.inherentRisk)}`}
              title={reportData?.assessmentRisk?.inherentRisk?.toUpperCase()}
              caption="INHERENT RISK"
              height={'100px'}
            ></MetricCard>
            <div className={styles['section-wrapper']}>
              <div className={styles['section-2']}>
                <div className={styles['high']}>
                  <div className={styles['value']}>
                    <div className={styles['value-2']}>
                      <div className={styles['text-wrapper-2']}>Data</div>
                    </div>
                    <div className={styles['text-wrapper-3']}>
                      {getDataTypesInvlovedInReport(reportData?.dataTypeInvloved)}
                    </div>
                  </div>
                  <div className={styles['levels']}>
                    <div
                      className={styles['rectangle']}
                      style={{
                        backgroundColor:
                          !reportData?.dataTypeInvloved || reportData?.dataTypeInvloved === '1'
                            ? 'var(--confirmation-color-CC-l-150)'
                            : 'var(--neutral-color-grey-150)',
                      }}
                    />
                    <div
                      className={styles['rectangle-flat']}
                      style={{
                        backgroundColor:
                          !!reportData?.dataTypeInvloved && reportData?.dataTypeInvloved === '2'
                            ? 'var(--warning-color-2-WC-l-150)'
                            : 'var(--neutral-color-grey-150)',
                      }}
                    />
                    <div
                      className={styles['rectangle-flat']}
                      style={{
                        backgroundColor:
                          !!reportData?.dataTypeInvloved && reportData?.dataTypeInvloved === '3'
                            ? 'var(--secondary-color-SC-l-150)'
                            : 'var(--neutral-color-grey-150)',
                      }}
                    />
                    <div
                      className={styles['rectangle-last']}
                      style={{
                        backgroundColor:
                          !!reportData?.dataTypeInvloved && reportData?.dataTypeInvloved === '4'
                            ? 'var(--error-color-EC-l-150)'
                            : 'var(--neutral-color-grey-150)',
                      }}
                    />
                  </div>
                </div>
                <div className={styles['high']}>
                  <div className={styles['value']}>
                    <div className={styles['value-2']}>
                      <div className={styles['text-wrapper-2']}>Saas</div>
                    </div>
                    <div className={styles['text-wrapper-3']}>
                      {questionaireData?.applicationInvolved === 'Y' ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className={styles['levels']}>
                    {questionaireData?.applicationInvolved === 'Y' ? (
                      <>
                        <div className={styles['rectangle-yes']} />
                        <div className={styles['rectangle-empty']} />
                      </>
                    ) : (
                      <>
                        <div className={styles['rectangle']} />
                        <div className={styles['rectangle-no']} />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles['high']}>
                  <div className={styles['value']}>
                    <div className={styles['value-2']}>
                      <div className={styles['text-wrapper-2']}>External Access</div>
                    </div>
                    <div className={styles['text-wrapper-3']}>
                      {' '}
                      {questionaireData?.vendorHaveNetworkAccess === 'Y' ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className={styles['levels']}>
                    {questionaireData?.vendorHaveNetworkAccess === 'Y' ? (
                      <>
                        <div className={styles['rectangle-yes']} />
                        <div className={styles['rectangle-empty']} />
                      </>
                    ) : (
                      <>
                        <div className={styles['rectangle']} />
                        <div className={styles['rectangle-no']} />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles['high']}>
                  <div className={styles['value']}>
                    <div className={styles['value-2']}>
                      <div className={styles['text-wrapper-2']}>Customer Data</div>
                    </div>
                    <div className={styles['text-wrapper-3']}>
                      {questionaireData.customerDataInvolved === 'Y' ||
                      (questionaireData?.typeOfCustomerData &&
                        questionaireData?.typeOfCustomerData.length > 0)
                        ? 'Yes'
                        : 'No'}
                    </div>
                  </div>
                  <div className={styles['levels']}>
                    {questionaireData.customerDataInvolved === 'Y' ||
                    (questionaireData?.typeOfCustomerData &&
                      questionaireData?.typeOfCustomerData.length > 0) ? (
                      <>
                        <div className={styles['rectangle-yes']} />
                        <div className={styles['rectangle-empty']} />
                      </>
                    ) : (
                      <>
                        <div className={styles['rectangle']} />
                        <div className={styles['rectangle-no']} />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles['high']}>
                  <div className={styles['value']}>
                    <div className={styles['value-2']}>
                      <div className={styles['text-wrapper-2']}>Fourth Party Access</div>
                    </div>
                    <div className={styles['text-wrapper-3']}>
                      {fourthPartyAccessAnswer === 'Y' ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className={styles['levels']}>
                    {fourthPartyAccessAnswer === 'Y' ? (
                      <>
                        <div className={styles['rectangle-yes']} />
                        <div className={styles['rectangle-empty']} />
                      </>
                    ) : (
                      <>
                        <div className={styles['rectangle']} />
                        <div className={styles['rectangle-no']} />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles['high']}>
                  <div className={styles['value']}>
                    <div className={styles['value-2']}>
                      <div className={styles['text-wrapper-2']}>Application Dependability</div>
                    </div>
                    <div className={styles['text-wrapper-3']}>
                      {questionaireData?.mobileAppInvolved === 'Y' ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className={styles['levels']}>
                    {questionaireData?.mobileAppInvolved === 'Y' ? (
                      <>
                        <div className={styles['rectangle-yes']} />
                        <div className={styles['rectangle-empty']} />
                      </>
                    ) : (
                      <>
                        <div className={styles['rectangle']} />
                        <div className={styles['rectangle-no']} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className={styles['rectangle-6']} />
          <Card className={styles['control-eff-wrapper']}>
            <MetricCard
              color={`${applyColorSchemeForRiskBoxes(
                reportData?.assessmentRisk?.controlEffectiveness,
              )}`}
              title={reportData?.assessmentRisk?.controlEffectiveness?.toUpperCase()}
              caption="CONTROL EFFECTIVENESS"
              height={'100px'}
            ></MetricCard>
            <div className={styles['risk-level']}>
              <div className={styles['high-2']}>
                <div className={styles['value-3']}>
                  <div className={styles['text-wrapper-6']}>Control testing metrics</div>
                </div>
                <div className={styles['text-wrapper-7']}>
                  {parseInt(reportData?.controlTestingMetrics?.overallSecureScore)}%
                </div>
              </div>
              <div className={styles['high-2']}>
                <div className={styles['value-3']}>
                  <div className={styles['text-wrapper-6']}>Total gaps</div>
                </div>
                <div className={styles['text-wrapper-7']}>
                  {reportData?.controlTestingMetrics?.failedControls}
                </div>
              </div>
              {/* <div className={styles['high-2']}>
                <div className={styles['value-3']}>
                  <div className={styles['text-wrapper-6']}>Gaps severity</div>
                </div>
                <div className={styles['text-wrapper-7']}>{0}</div>
              </div> */}
              <div className={styles['high-3']}>
                <div className={styles['value-3']}>
                  <div className={styles['text-wrapper-6']}>Critical gaps</div>
                </div>
                <div className={styles['text-wrapper-7']}>
                  {reportData?.controlTestingMetrics?.failedCiriticalControls}
                </div>
              </div>
            </div>
          </Card>
          <div className={styles['rectangle-6']} />
          <Card className={styles['div-wrapper']} style={{ marginRight: '10px' }}>
            <MetricCard
              color={`${applyColorSchemeForRiskBoxes(reportData?.assessmentRisk?.residualRisk)}`}
              title={reportData?.assessmentRisk?.residualRisk?.toUpperCase()}
              caption="RESIDUAL RISK"
              height={'100px'}
            ></MetricCard>
            <div className={styles['risk-level-2']}>
              <div className={styles['high-4']}>
                <div className={styles['value-4']}>
                  <div className={styles['text-wrapper-10']}>INHERENT RISK</div>
                </div>
                <div className={styles['text-wrapper-11']}>
                  {' '}
                  {capitalizeFirstLetter(reportData?.assessmentRisk?.inherentRisk)}
                </div>
              </div>
              <div className={styles['high-5']}>
                <div className={styles['value-4']}>
                  <div className={styles['text-wrapper-10']}>CONTROL EFFECTIVENESS</div>
                </div>
                <div className={styles['text-wrapper-11']}>
                  {capitalizeFirstLetter(reportData?.assessmentRisk?.controlEffectiveness)}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Modal>
  )
}
