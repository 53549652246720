import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Toasts from "./components/toast/Toast";
import AxiosInterceptor from "../context/axios";
import { AuthProvider, useAuthState } from "../context/login";
import { ToastProvider } from "../context/toast";
import "./scss/style.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";
import "./App.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import Snack from "./components/Snack";
import CompletedAssessments from "src/pages/dashboard/views/completed";
import AllAssessments from "src/pages/dashboard/views/list";
import AssessmentDetails from "src/pages/dashboard/views/details";
import Remediations from "src/pages/remediations";
import RemediationDetails from "src/pages/remediations/RemediationDetails";
import { AssessmentReport } from "src/pages/Report";
import ImpersonateRedirect from "src/pages/redirect";

const Login = React.lazy(() => import("../pages/Login"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

const VendorAssessments = React.lazy(() =>
  import("../pages/vendor-assessments")
);
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Questionnaire = React.lazy(() => import("../pages/questionnaire"));
const Documentary = React.lazy(() => import("../pages/documentary"));
const Assessment = React.lazy(() =>
  import("../pages/vendor-assessments/assessment")
);
const ManageTeam = React.lazy(() => import("../pages/manage-team"));

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
      text: {
        secondary: "#E8E8E8", // Your custom color for text.secondary
      },
    },
    components: {
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: "#2c2c2c", // Base background
          },
          wave: {
            "&::after": {
              background: "linear-gradient(90deg, #2c2c2c, #3c3c3c, #2c2c2c)", // Wave effect
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            color: "#E8E8E8",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-error": {
              border: "2px solid #d32f2f",
            },
            "&.Mui-focused": {
              border: "2px solid #5885E6",
            },
            "& fieldset": {
              border: "none",
            },
          },
          input: {
            color: "var(--neutral-color-grey-950)",
            flex: 1,
            fontFamily: "var(--paragraph-SB-p1-font-family)",
            fontSize: "var(--paragraph-SB-p1-font-size)",
            fontStyle: "var(--paragraph-SB-p1-font-style)",
            fontWight: "var(--paragraph-SB-p1-font-weight)",
            letterSpacing: "var(--paragraph-SB-p1-letter-spacing)",
            lineHeight: "var(--paragraph-SB-p1-line-height)",
            marginTop: "-1px",
            position: "relative",
            "&::placeholder": {
              color: '"var(--neutral-color-grey-450)',
              flex: 1,
              fontFamily: "var(--paragraph-r-p2-font-family)",
              fontSize: "var(--paragraph-r-p2-font-size)",
              fontStyle: "var(--paragraph-r-p2-font-style)",
              fontWeight: "var(--paragraph-r-p2-font-weight)",
              letterSpacing: "var(--paragraph-r-p2-letter-spacing)",
              lineHeight: "var(--paragraph-r-p2-line-height)",
              position: "relative",
            },
            "&::-internal-autofill-selected": {
              backgroundColor: "var(--neutral-color-grey-950)",
            },
            // '&:hover:not(.Mui-disabled, .Mui-error):before': {
            //   borderBottom: '2px solid "var(--TextField-brandBorderHoverColor)',
            // },
            // '&.Mui-focused:after': {
            //   borderBottom: '2px solid "var(--TextField-brandBorderFocusedColor)',
            // },
          },
        },
      },
      // '&.Mui-focused': {
      //   border: '2px solid blue',
      //   '& .MuiOutlinedInput-notchedOutline': {
      //     border: '2px solid blue',
      //   },
      // },
      MuiButton: {
        styleOverrides: {
          root: {
            "&.MuiButton-colorSecondary": {
              backgroundColor: "var(--neutral-color-grey-150)",
              color: "#fafafa",
              "&.Mui-disabled": {
                color: "rgb(255 255 255 / 38%)",
              },
            },
            "&.MuiButton-colorPrimary": {
              backgroundColor: "var(--info-color-IC-base)",
              color: "#fafafa",
              "&.Mui-disabled": {
                color: "rgb(255 255 255 / 38%)",
              },
            },
            "&.MuiButton-containedError": {
              backgroundColor: "var(--neutral-color-grey-150)",
              color: "#F26C6E",
              "&.Mui-disabled": {
                color: "rgb(255 255 255 / 38%)",
              },
            },
            "&.MuiButton-outlinedPrimary": {
              backgroundColor: "transparent !important",
            },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#F3F3F4",
            },
          },
          textColorPrimary: {
            color: "#A2A3A4",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: { backgroundColor: "#F3F3F4" },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#7F8082",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#7F8082",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            "&.MuiDialog-paper": {
              backgroundColor: "initial",
              color: "#F3F3F4",
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            background: "transparent !important",
            boxShadow: "none",
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            background: "transparent !important",
            boxShadow: "none",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "var(--collection-BG-500) !important",
            boxShadow: "none",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            "&.Mui-checked": {
              color: "rgba(255, 255, 255, 1)",
            },
          },
          track: {
            backgroundColor: "var(--neutral-color-grey-750)",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: "var(--collection-BG-100)",
            color: "white",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          expandIconWrapper: {
            color: "white",
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            color: "var(--neutral-color-grey-750)",
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: "var(--neutral-color-grey-750)",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "var(--neutral-color-grey-750) !important",
            "&.MuiDataGrid-overlay": {
              backgroundColor: "red !important",
            },
          },
        },
      },
    },
  });

const App = () => {
  const outerTheme = useTheme();
  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <ToastProvider>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          Components={{
            default: Snack,
          }}
        >
          <AxiosInterceptor>
            <BrowserRouter basename={"/vendor"}>
              <AuthProvider>
                <SkeletonTheme baseColor="#ffffff1c" highlightColor="#656871">
                  <Suspense fallback={loading}>
                    <Routes>
                      <Route
                        exact
                        path="/login"
                        name="Login Page"
                        element={<Login />}
                      />
                      <Route
                        path="/redirect"
                        element={<ImpersonateRedirect />}
                      />
                      <Route
                        path="/"
                        name="Home"
                        element={
                          <ProtectedComponent>
                            <DefaultLayout />
                          </ProtectedComponent>
                        }
                      >
                        <Route path={`/dashboard`} element={<Dashboard />} />
                        <Route
                          path={`/completed-assessments`}
                          element={<CompletedAssessments />}
                        />
                        <Route
                          path={`/all-assessments`}
                          element={<AllAssessments />}
                        />
                        <Route
                          path={`/assessment-details/:assessmentId`}
                          element={<AssessmentDetails />}
                        />
                        <Route
                          path={`/assessments`}
                          element={<VendorAssessments />}
                        />
                        <Route
                          path={`/assessment/:assessmentId/questionnaire/:id`}
                          element={<Questionnaire />}
                        />
                        <Route
                          path={`/assessment/:assessmentId/documentary/:id`}
                          element={<Documentary />}
                        />
                        <Route
                          path={`/assessment/:id`}
                          element={<Assessment />}
                        />

                        <Route
                          path={`/remediations`}
                          element={<Remediations />}
                        />
                        <Route
                          path={`/remediation/:id`}
                          element={<RemediationDetails />}
                        />
                        <Route path={`/users`} element={<ManageTeam />} />
                        <Route
                          path={`/assessment-report`}
                          element={<AssessmentReport />}
                        />
                        <Route
                          path="*"
                          element={<Navigate to="/dashboard" replace />}
                        />
                      </Route>
                      <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                      />
                    </Routes>
                  </Suspense>
                </SkeletonTheme>
                <Toasts />
              </AuthProvider>
            </BrowserRouter>
          </AxiosInterceptor>
        </SnackbarProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export const ProtectedComponent = ({ children }) => {
  const userDetails = useAuthState();
  return !Boolean(userDetails.token) ? (
    <Navigate to="/login" replace />
  ) : (
    <>{children}</>
  );
};

export default App;
