import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import {
  getEvidence,
  getRemediations,
} from "../../services/remediationService";
import { useAuthState } from "../../context/login";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { Button, Grid } from "@mui/material";
import { hasValue } from "src/utils/commonFns";
import RemediationResponseModal from "./RemediationResponseModal";
import { CircleLoader } from "src/v2/components/CircleLoader";
export default function Remediations() {
  const { user } = useAuthState();

  useEffect(() => {
    loadRemediations();
  }, []);

  const [remediations, setRemediations] = useState([]);
  const [selectedRemediation, setSelectedRemediation] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [openEvidenceModal, setOpenEvidenceModal] = useState(null);

  const [evidenceDocument, setEvidenceDocument] = useState({
    fileName: "",
    fileSignedURL: "",
    fileslist: null,
  });

  const [remarks, setRemarks] = useState("");

  const loadRemediations = async () => {
    try {
      setLoading(true);
      const { result } = await getRemediations(user.vendorId);
      setRemediations(result);
      if (result?.length > 0) setSelectedRemediation(result[0]);
    } finally {
      setLoading(false);
    }
  };

  const loadEvidence = async () => {
    if (!hasValue(selectedRemediation?.remediationGapId)) return;
    const { result } = await getEvidence(selectedRemediation?.remediationGapId);
    if (result) {
      setEvidenceDocument({
        fileName: result?.evidenceFileName || "",
        fileSignedURL: result?.evidenceFileSignedUrl || "",
      });
      setRemarks(result.remarks);
    }
  };

  useEffect(() => {
    loadEvidence();
  }, [selectedRemediation]);

  const remediationResponseModalHandle = (isSave) => {
    setOpenEvidenceModal(null);
    if (isSave) {
      loadRemediations();
    }
  };

  return (
    <Fragment>
      <Grid container spacing={1} className={classes["remediation-container"]}>
        <Grid item xs={3} className={classes["remediation-card-container"]}>
          {loading && (
            <div className={classes.loader}>
              <CircleLoader size={100}></CircleLoader>
            </div>
          )}
          {!loading &&
            remediations.map((remediation, index) => (
              <div
                className={clsx(
                  classes["remediation-card"],
                  selectedRemediation?.remediationID ===
                    remediation.remediationID &&
                    classes["remediation-card-active"]
                )}
                key={index}
                onClick={() => {
                  setSelectedRemediation(remediation);
                }}
              >
                <div className={classes["remediation-card-header"]}>
                  <div className={classes["remediation-card-body-domain"]}>
                    {remediation.domainName}
                  </div>

                  <div className={classes["remediation-card-header-status"]}>
                    {remediation.displayStatus}
                  </div>
                </div>
                <div className={classes["remediation-card-body"]}>
                  <div
                    className={classes["remediation-card-header-business-org"]}
                  >
                    {remediation.businessOrgName}
                  </div>
                  <div
                    className={classes["remediation-card-body-initiated-on"]}
                  >
                    {moment(remediation.initiatedOn).format("MMM DD, YYYY")}
                  </div>
                </div>
              </div>
            ))}
        </Grid>
        <Grid item xs={9} className={classes["remediation-detail-container"]}>
          {loading && (
            <div className={classes.loader}>
              <CircleLoader size={100}></CircleLoader>
            </div>
          )}
          {!loading && selectedRemediation && (
            <Card className={classes.card}>
              <CardBody style={{ padding: 0 }}>
                {selectedRemediation?.displayStatus !== "Submitted" && (
                  <div>
                    <Button
                      onClick={() =>
                        setOpenEvidenceModal(selectedRemediation.remediationID)
                      }
                      className={classes["remediation-evidence"]}
                      sx={{ float: "right" }}
                    >
                      Submit Evidence / Remarks to Beaconer
                    </Button>
                  </div>
                )}
                <Grid
                  container
                  rowGap={3}
                  className={classes["remediation-details"]}
                >
                  <Grid item xs={6}>
                    <div className={classes["remediation-sub-heading"]}>
                      Domain Name
                    </div>
                    <div className={classes["remediation-value"]}>
                      {selectedRemediation.domainName}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes["remediation-sub-heading"]}>
                      Severity
                    </div>
                    <div className={classes["remediation-value"]}>
                      {selectedRemediation.issueSeverity}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes["remediation-sub-heading"]}>
                      Issue Identified
                    </div>
                    <div className={classes["remediation-value"]}>
                      {selectedRemediation.issueIdentified}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes["remediation-sub-heading"]}>
                      Risk
                    </div>
                    <div className={classes["remediation-value"]}>
                      {selectedRemediation.risk}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes["remediation-sub-heading"]}>
                      Remediation
                    </div>
                    <div className={classes["remediation-value"]}>
                      {selectedRemediation.remediation}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes["remediation-sub-heading"]}>
                      Evidence
                    </div>
                    <div className={classes["remediation-value"]}>
                      {selectedRemediation.evidence}
                    </div>
                  </Grid>
                  {(hasValue(evidenceDocument.fileName) &&
                    hasValue(evidenceDocument.fileSignedURL)) ||
                  hasValue(remarks) ? (
                    <hr style={{ width: "100%" }} />
                  ) : null}
                  {hasValue(evidenceDocument.fileName) &&
                    hasValue(evidenceDocument.fileSignedURL) && (
                      <Grid item xs={12}>
                        <div className={classes["remediation-sub-heading"]}>
                          Submitted Evidence
                        </div>
                        <div className={classes["remediation-value"]}>
                          <a
                            href={evidenceDocument?.fileSignedURL}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {evidenceDocument.fileName}
                          </a>
                        </div>
                      </Grid>
                    )}
                  {hasValue(remarks) && (
                    <Grid item xs={12}>
                      <div className={classes["remediation-sub-heading"]}>
                        Remarks
                      </div>
                      <div className={classes["remediation-value"]}>
                        {remarks}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </CardBody>
            </Card>
          )}
        </Grid>
      </Grid>
      {hasValue(openEvidenceModal) && (
        <RemediationResponseModal
          {...{
            remediationGapEvidenceId: selectedRemediation?.remediationGapId,
            remediationResponseModalHandle,
          }}
        />
      )}
    </Fragment>
  );
}
