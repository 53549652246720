import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuthDispatch } from "../../context/login";
import { hasValue } from "../../utils/commonFns";

//trigger
const ImpersonateRedirect = () => {
  const dispatch = useAuthDispatch();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("response");

  useEffect(() => {
    if (hasValue(code)) {
      const data = JSON.parse(atob(code));
      localStorage.setItem("currentUser", JSON.stringify(data));
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      navigate("/assessments");
    }
  }, []);

  return (
    <div style={{ margin: "30px" }}>
      <h5>Redirecting...</h5>
    </div>
  );
};

export default ImpersonateRedirect;
