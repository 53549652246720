import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import { RemediationCard } from "./RemediationCard";
import SideDrawer from "./SideDrawer/SideDrawer";
import { getRemediationsByClientSubmissionIdV2 } from "src/services/remediationService";

export const Remediations = ({ assessmentDetails }) => {
  const reportData = JSON.parse(assessmentDetails?.reportData);
  const remediationGaps = reportData?.gapsRecommendations;

  const [remediations, setRemediations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(null);
  const mapRemediations = (index, count, sectionsCount, result) => {
    var startdataIndex = index * 3;
    var endIndex = startdataIndex + 3;
    const cards = [];
    for (let index = startdataIndex; index < endIndex; index++) {
      if (index < count)
        cards.push(
          <RemediationCard
            key={index}
            eachGap={result[index]}
            setOpenDrawer={setOpenDrawer}
          />
        );
      else
        cards.push(<div className={styles["empty-remediation-cards"]}></div>);
    }
    return cards;
  };

  const mapSections = (count, sectionsCount, result) => {
    const sections = [];
    for (let index = 0; index < sectionsCount; index++) {
      sections.push(
        <div className={styles["cards-secetion"]}>
          <div style={{ width: "100%", display: "flex", gap: "20px" }}>
            {mapRemediations(index, count, sectionsCount, result)}
          </div>
        </div>
      );
    }
    return sections;
  };

  const getRemediations = async () => {
    if (!!assessmentDetails && !!assessmentDetails.id) {
      try {
        setIsLoading(true);
        const { result } = await getRemediationsByClientSubmissionIdV2(
          assessmentDetails.id
        );
        if (!!result) {
          // var count = result?.length
          // var sectionsCount = (count / 3) % 1 === 0 ? count / 3 : parseInt(count / 3) + 1
          // setRemediations(mapSections(count, sectionsCount, result))
          if (!!remediationGaps && remediationGaps.length > 0) {
            var updatedGaps = [...remediationGaps];
            result.forEach((eachRemediation) => {
              var gapIndex = remediationGaps.findIndex(
                (eachGap) => eachGap.id === eachRemediation.reportReferenceId
              );
              if (gapIndex !== -1) {
                updatedGaps.splice(gapIndex, 1, eachRemediation);
              }
            });
            loadRemediations(updatedGaps);
          } else loadRemediations(remediationGaps);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const loadRemediations = (gaps) => {
    var count = gaps?.length;
    var sectionsCount =
      (count / 3) % 1 === 0 ? count / 3 : parseInt(count / 3) + 1;
    setRemediations(mapSections(count, sectionsCount, gaps));
  };

  useEffect(() => {
    getRemediations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isLoading && remediations && remediations.length > 0 && (
        <div>
          <div className={styles.remediation}>{remediations}</div>
          {!!openDrawer && (
            <SideDrawer
              {...{
                openDrawer,
                setOpenDrawer,
              }}
            />
          )}
        </div>
      )}
      {!isLoading && (!remediations || remediations.length <= 0) && (
        <div className={styles["loader-wrapper"]}>No Data Available</div>
      )}
    </>
  );
};
