import React from "react";
import classes from "./style.module.css";
import { IconPlaceholder } from "src/v2/components/IconPlaceholder";
import { CloseIcon } from "src/v2/assets/icons/remediation/sidesheet/close";

export const Header = ({ remediation, setOpenDrawer }) => {
  return (
    <header className={classes.header}>
      <div className={classes["title-close"]}>
        <div className={classes.div}>
          <div className={classes.title}>{remediation.domainName}</div>
          <div className={classes["link-2"]}>
            <div className={classes.date}>Remediation</div>
          </div>
        </div>
        <IconPlaceholder
          icon={<CloseIcon className={classes["icon-instance-node"]} />}
          size="twenty-eight-x-28"
          onClick={() => setOpenDrawer(null)}
        />
      </div>
    </header>
  );
};
