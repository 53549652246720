import React, { useEffect, useState } from "react";
import { hasValue } from "../../utils/commonFns";
import { getEvidence, uploadEvidence } from "../../services/remediationService";
import { useSnackbar } from "notistack";
import classes from "./styles.module.scss";
import { Button, CircularProgress, Dialog, InputBase } from "@mui/material";
import { IconPlaceholder } from "src/v2/components/IconPlaceholder";
import { CrossMark } from "src/v2/icons/cross-mark";
import ChooseFileDragnDrop from "../documentary/chooseFileDragDrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const RemediationResponseModal = ({
  remediationGapEvidenceId,
  remediationResponseModalHandle,
}) => {
  const [evidenceDocument, setEvidenceDocument] = useState({
    fileName: "",
    fileSignedURL: "",
    fileslist: null,
  });

  const [saving, setSaving] = useState(false);

  const [remarks, setRemarks] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (remediationGapEvidenceId) {
      setEvidenceDocument({
        fileName: "",
        fileSignedURL: "",
        fileslist: null,
      });
      setRemarks("");
      loadEvidence();
    }
  }, [remediationGapEvidenceId]);

  const loadEvidence = async () => {
    const { result } = await getEvidence(remediationGapEvidenceId);
    if (result) {
      if (result?.evidenceFileName && result?.evidenceFileSignedUrl) {
        setEvidenceDocument({
          fileName: result?.evidenceFileName || "",
          fileSignedURL: result?.evidenceFileSignedUrl,
        });
      }
      setRemarks(result.remarks);
    }
  };

  const handleSave = async () => {
    if (!hasValue(evidenceDocument.fileName) && !hasValue(remarks)) {
      enqueueSnackbar("Either Evidence or Remarks are mandatory", {
        variant: "error",
      });
      return;
    }

    try {
      setSaving(true);
      const request = new FormData();
      request.append("RemediationGapId", remediationGapEvidenceId);
      if (hasValue(evidenceDocument.fileslist))
        request.append("EvidenceFile", evidenceDocument.fileslist);
      request.append("Remarks", remarks);

      const { result } = await uploadEvidence(request);

      if (result) {
        enqueueSnackbar("Submitted to Beaconer successfully", {
          variant: "success",
        });
        remediationResponseModalHandle(true);
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Dialog
        open={hasValue(remediationGapEvidenceId)}
        className={classes.modal}
        style={{ width: "100%" }}
      >
        <div className={classes.modal}>
          <div className={classes.title}>
            <div className={classes["title-icon"]}>
              <div className={classes["text-wrapper"]}>
                {" "}
                {"Submit Evidence"}
              </div>
            </div>
            <IconPlaceholder
              icon={
                <CrossMark
                  className={`${"menu-links"} ${classes["icon-instance-node"]}`}
                />
              }
              size="twenty-four-x-24"
              onClick={() => remediationResponseModalHandle(false)}
            />
          </div>
          <div className={classes.description}>
            <p className={classes["description-text"]}>
              Please Select Evidence Report
            </p>
            {!hasValue(evidenceDocument.fileSignedURL) &&
            hasValue(evidenceDocument.fileName) ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p>{evidenceDocument.fileName}</p>
                <FontAwesomeIcon
                  className="ml-4 cursor-pointer"
                  style={{
                    fontSize: 14,
                    marginLeft: "auto",
                    fontWeight: "bold",
                  }}
                  icon={faTrash}
                  onClick={() => {
                    setEvidenceDocument({
                      fileName: "",
                      fileSignedURL: "",
                      fileslist: null,
                    });
                  }}
                />
              </div>
            ) : (
              <a href={evidenceDocument?.fileSignedURL} target="_">
                {evidenceDocument.fileName}
              </a>
            )}
            <ChooseFileDragnDrop
              files={
                !!evidenceDocument.fileslist ? [evidenceDocument.fileslist] : []
              }
              setFiles={(files) => {
                if (files?.length > 0) {
                  setEvidenceDocument({
                    fileName: files[0].name,
                    fileSignedURL: "",
                    fileslist: files[0],
                  });
                }
              }}
              isReadOnlyMode={true}
              isSingle={true}
            />
            <p className={classes["description-text"]}>{"Remarks"}</p>
            <InputBase
              placeholder={"Enter Remarks"}
              multiline
              rows={5}
              className={classes["reason"]}
              id="Remarks"
              name="Remarks"
              onChange={(evt) => setRemarks(evt.target.value)}
              value={remarks}
              required
            ></InputBase>
          </div>
          <div className={classes.div}>
            <Button
              className={classes["button-instance"]}
              color="error"
              size="medium"
              variant="contained"
              text={"Cancel"}
              onClick={() => remediationResponseModalHandle(false)}
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              className={classes["button-instance"]}
              color="primary"
              size="medium"
              variant="contained"
              text={"Save"}
              onClick={handleSave}
              autoFocus
              style={{ textTransform: "none" }}
              disabled={saving}
            >
              {saving ? (
                <CircularProgress
                  sx={{ color: "#fff", margin: "0px 12px" }}
                  size={"22px"}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RemediationResponseModal;
