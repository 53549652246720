import axios from "axios";
import AppConfig from "../config";

const baseURL = `${AppConfig.API_URL}/Vendor`;

const getVendors = async () => {
  try {
    let { data } = await axios.get(baseURL);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getVendorById = async (vendorId) => {
  try {
    let { data } = await axios.get(`${baseURL}/${vendorId}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const saveVendor = async (vendor) => {
  try {
    let { data } = await axios.post(`${baseURL}`, JSON.stringify(vendor));
    if (data.success) {
      return data.success;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const updateVendor = async (vendor) => {
  try {
    let { data } = await axios.put(`${baseURL}`, JSON.stringify(vendor));
    if (data.success) {
      return data.success;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getClientSubmissionDetails = async (vendorId) => {
  try {
    let { data } = await axios.get(
      `${AppConfig.API_URL}/ClientSubmissions/details?vendorId=${vendorId}`
    );
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export {
  getVendors,
  getVendorById,
  saveVendor,
  updateVendor,
  getClientSubmissionDetails,
};
